

h1 {
    font-size: 35px;
    color: white;
    text-align: left;
    margin: 0;
}

h2 {
    font-size: 25px;
    color: white;
    margin: 0;
}

h3 {
    font-size: 18px;
    color: white;
    margin: 0;
}

h4 {
    font-size: 15px;
    color: white;
    margin: 0;
}

h5 {
    font-size: 12px;
    color: white;
    margin: 0;
}

h6 {
    font-size: 10px;
    color: white;
    margin: 0;
}

p {
    color: white;
}

a {
    display: inline-block;
    text-decoration: none;
}

.date-input {
    box-sizing: border-box;
    margin-right: 10px;
    padding: 5px;

    background-color:rgb(16, 18, 20);
    color: white;

    font-family: 'Montserrat';
    font-size: 12px;
    outline: none;
    border: 0;
    border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
}

*::-webkit-scrollbar {
    width: 13px;
}

*::-webkit-scrollbar-track {
    background: none; 
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(22, 25, 29); 
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.4); 
}


.mapboxgl-control-container {
    display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}