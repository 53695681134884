.mapboxgl-popup-content {
    background-color: rgb(22, 25, 29);
    width: 300px;
    height: fit-content;
    padding: 0;
    position: relative;
    border-radius: 5px;
    padding-bottom: 10px;
}

.mapboxgl-popup-content > div {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgb(22, 25, 29);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: rgb(22, 25, 29);
}






