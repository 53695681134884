@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



h1 {
    font-size: 35px;
    color: white;
    text-align: left;
    margin: 0;
}

h2 {
    font-size: 25px;
    color: white;
    margin: 0;
}

h3 {
    font-size: 18px;
    color: white;
    margin: 0;
}

h4 {
    font-size: 15px;
    color: white;
    margin: 0;
}

h5 {
    font-size: 12px;
    color: white;
    margin: 0;
}

h6 {
    font-size: 10px;
    color: white;
    margin: 0;
}

p {
    color: white;
}

a {
    display: inline-block;
    text-decoration: none;
}

.date-input {
    box-sizing: border-box;
    margin-right: 10px;
    padding: 5px;

    background-color:rgb(16, 18, 20);
    color: white;

    font-family: 'Montserrat';
    font-size: 12px;
    outline: none;
    border: 0;
    border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(1);
            filter: invert(1);
    cursor: pointer;
}

*::-webkit-scrollbar {
    width: 13px;
}

*::-webkit-scrollbar-track {
    background: none; 
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(22, 25, 29); 
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.4); 
}


.mapboxgl-control-container {
    display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}
.mapboxgl-popup-content {
    background-color: rgb(22, 25, 29);
    width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    position: relative;
    border-radius: 5px;
    padding-bottom: 10px;
}

.mapboxgl-popup-content > div {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgb(22, 25, 29);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: rgb(22, 25, 29);
}







.mapboxgl-popup-content {
    background-color: rgb(22, 25, 29);
    width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    position: relative;
    border-radius: 5px;
    padding-bottom: 10px;
}

.mapboxgl-popup-content > div {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgb(22, 25, 29);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: rgb(22, 25, 29);
}







